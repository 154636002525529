import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Container from '../components/container'
import DemoForm from '../components/demo_form'
import Subnav from '../components/subnav'
import Divider from '../components/divider'
import SimpleHeader from '../components/simple_header'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const OemSolutionsPage = ({ data }) => {

  const content = data.contentfulPage

  return (
    <Layout>
      <Seo
        title={content.seoTitle || content.title}
        description={content.metaDescription || content.subtitle}
      />
      <SimpleHeader
        title={content.title}
        subtitle={content.subtitle}
      />
      <Subnav
        isSticky={true}
        links={[
          ...content.items.map(item => (
            {
              key: `subnav_${item.url}`,
              href: `#${item.url}`,
              text: item.title
            }
          ))
        ]}
      />
      {content.items.map((item, index) => <React.Fragment key={item.url}>
        <div id={item.url} className={`Section Section--condensed ${index % 2 === 1 ? 'Section--stripe' : ''}`}>
          <Container size='lg'>
            <div className='text-center'>
              <img
                src={`https://media.roadster.com/makes/${item.title}.svg`}
                width={100}
                height={100}
                alt=''
              />
              <h2 className='uppercase text-gray text-base font-semi-bold tracking-wide mt-0'>
                {item.title}
              </h2>
            </div>
            <Divider className='my-4' />
            {item.items &&
              <div className='grid sm:grid-cols-2 gap-4 sm:gap-y-5'>
                {item.items.map(subitem =>
                  <div key={subitem.title}>
                    {subitem.image?.file?.url &&
                      <img
                        src={`${subitem.image.file.url}?&h=58`}
                        height={58}
                        alt=''
                      />
                    }
                    <h3 className='uppercase font-light text-xl'>
                      {subitem.title}
                    </h3>
                    {subitem.text &&
                      <div className='Section-text ml-0' style={{ maxWidth: '34em' }}>
                        {renderRichText(subitem.text)}
                      </div>
                    }
                  </div>
                )}
              </div>
            }
          </Container>
        </div>
      </React.Fragment>)}
      <div className='Section Section--dark'>
        <Container>
          <DemoForm invert={true} />
        </Container>
      </div>
    </Layout>
  )
}

export default OemSolutionsPage

export const pageQuery = graphql`
  query {
    contentfulPage(url: {eq: "oem_solutions"}) {
      title
      seoTitle
      metaDescription
      subtitle
      items {
        ... on ContentfulPage {
          title
          items {
            ... on ContentfulCopy {
              title
              image {
                file {
                  url
                }
              }
              text {
                raw
              }
            }
          }
          url
        }
      }
    }
  }
`
